<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-tea</v-icon>
               <h2>¿Cuántas tazas de té tomas al día?</h2>
           </v-col>
           <v-col cols="12" sm="12" md="4" class="pb-0">
               <v-text-field
                    v-model="tee"
                    :error-messages="teeErrors"
                    label="Consumo de té"
                    outlined
                    @input="setTee($event)"
                    @blur="$v.tee.$touch()"
                    inputmode="numeric"
                ></v-text-field>
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q10"
                    >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    :disabled="$v.$invalid"
                    to="/q12"
                    >
                    Finalizar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
       </v-row>
   </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, numeric } from 'vuelidate/lib/validators'

export default {
    
    mixins: [validationMixin],

    validations: {
      tee: { required, numeric },
    },

    data() {
        return {
            tee: ''
        }
    },

    mounted() {
        this.tee = this.$store.state.tee
        this.$store.state.progress = 65
        this.goBackIfEmpty()
    },

    computed: {
        teeErrors () {
            const errors = []
            if (!this.$v.tee.$dirty) return errors
            !this.$v.tee.required && errors.push('Consumo de té es requerido.')
            !this.$v.tee.numeric && errors.push('Debe ser un número.')
            return errors
        },
    },

    methods: {
        setTee(value) {
            this.$store.state.tee = value
            this.$v.tee.$touch()
        },
        goBackIfEmpty(){
           if(!this.$store.state.municipio) {
                this.$router.push('/municipio')
           }else if(!this.$store.state.personas) {
                this.$router.push('/personas')
           }else if(!this.$store.state.cereales) {
                this.$router.push('/q1')
           }else if(!this.$store.state.carnes) {
               this.$router.push('/q2')
           }else if(!this.$store.state.lacteos) {
               this.$router.push('/q3')
           }else if(!this.$store.state.huevos) {
               this.$router.push('/q4')
           }else if(!this.$store.state.comida) {
               this.$router.push('/q5')
           }else if(!this.$store.state.dulces) {
               this.$router.push('/q6')
           }else if(!this.$store.state.verduras) {
               this.$router.push('/q7')
           }else if(!this.$store.state.frutas) {
               this.$router.push('/q8')
           }else if(!this.$store.state.tuberculos) {
               this.$router.push('/q9')
           }else if(!this.$store.state.cafe) {
               this.$router.push('/q10')
           }
       }
    },
}
</script>